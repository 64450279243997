import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash-es';
import { ComplexRating } from 'src/app/shared/shared.definitions';

export function minimumChoicesValidator(minChoices: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const checkedCount = control?.value?.length || 0;

    if (checkedCount < minChoices && control?.value?.length > 0) {
      return {
        minChoices: {
          valid: false,
          min: minChoices,
          actual: checkedCount,
        },
      };
    }

    return null;
  };
}

export function maximumChoicesValidator(maxChoices: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const checkedCount = control?.value?.length;
    if (checkedCount > maxChoices) {
      return {
        maxChoices: {
          valid: false,
          required: true,
          max: maxChoices,
          actual: checkedCount,
        },
      };
    }
    return null;
  };
}

export function arrayLengthValidator(length: number, isFile = false): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value: any[] = control.value;
    if (isFile && !control.value.length) {
      return null;
    }
    if (Array.isArray(value) && value.length !== length) {
      return { arrayLength: { requiredLength: length, actualLength: value.length } };
    }
    return null;
  };
}

export function arrayUniqueValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value: any[] = control?.value;
    const array = value?.map((s) => +s.numberOf);
    if (array?.some((n) => n > array.length)) {
      return {
        arrayUniqueValidator: {
          valid: false,
          required: true,
        },
      };
    }
    if (new Set(array)?.size !== array?.length) {
      return {
        arrayUniqueValidator: {
          valid: false,
          required: true,
        },
      };
    }
    if (array.some((a) => a === null)) {
      return {
        arrayUniqueValidator: {
          valid: false,
          required: true,
        },
      };
    }
    return null;
  };
}

export function passwordMatchValidator(password: AbstractControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const confirmPassword = control;

    if (password && confirmPassword && password.value !== confirmPassword.value) {
      return { passwordMismatch: true };
    }

    return null;
  };
}

export function maxCheckboxLimit(limit: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!(control instanceof FormGroup)) {
      return null;
    }

    const checkedCount = Object.values(control.controls).filter((ctrl: AbstractControl) => ctrl.value === true).length;

    if (checkedCount > limit) {
      return {
        maxCheckboxLimitExceeded: {
          valid: false,
          required: true,
          limit,
        },
      };
    }

    return null;
  };
}

export function exclusiveCategorySelection(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!(control instanceof FormGroup)) {
      return null;
    }

    const selectedCategories = [];

    Object.entries(control.controls).forEach((entry: [string, AbstractControl]) => {
      const [groupKey, groupValue] = entry;

      if (groupValue && groupValue instanceof FormGroup && Object.values(groupValue.getRawValue()).some((value: any) => value === true)) {
        selectedCategories.push(groupKey);
      }
    });

    if (selectedCategories.length > 1) {
      return { exclusiveCategorySelection: true };
    }

    return null;
  };
}

export function atLeastOneValueValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const atLeastOneValue = Object.values(control.value).some((groupValue) => {
      return Object.values(groupValue as any).includes(true);
    });
    return atLeastOneValue ? null : { atLeastOneValueRequired: true };
  };
}

export function objectValuesRequiredValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value && typeof control.value === 'object') {
      const values = Object.values(control.value);
      if (values.some((value) => value === '' || value === null)) {
        return { objectValueRequired: true };
      }
    }
    return null;
  };
}

export function customDateRangeValidator(translate: TranslateService): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const dates = value?.split(',').map((date: any) => date.trim());

    if (!value || dates.length !== 2) {
      return { customDateRange: true, message: translate.instant('formErrors.required.bothDates') };
    }

    if (!isValidDate(dates[0]) || !isValidDate(dates[1])) {
      return { customDateRange: true, message: 'A dátumok formátuma nem megfelelő!' };
    }

    return null;
  };
}

function isValidDate(dateStr: string): boolean {
  const date = new Date(dateStr);
  return !isNaN(date.getTime());
}

export function serverErrorValidator(errorMessage: string): ValidatorFn {
  let cachedValue: unknown;
  let validatedOnce = false;

  return (control: AbstractControl): ValidationErrors | null => {
    if (!(control instanceof FormControl)) {
      return null;
    }

    if (!validatedOnce) {
      validatedOnce = true;
    } else if (!isEqual(control.value, cachedValue)) {
      control.clearValidators();
      control.updateValueAndValidity();
      cachedValue = control.value;
      return null;
    }

    if (errorMessage) {
      return { serverError: errorMessage };
    }

    return null;
  };
}

export function atLeastOneTrueValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!(control instanceof FormGroup)) {
      return null;
    }

    let atLeastOneTrue = false;

    Object.keys(control.controls).forEach((controlName) => {
      const nestedControl = control.get(controlName);

      if (nestedControl && nestedControl.value === true) {
        atLeastOneTrue = true;
      }
    });

    return atLeastOneTrue ? null : { atLeastOneTrue: true };
  };
}

export function listingScreenValuesNotEmptyValidator(screen2Req: boolean, screen3Req: boolean): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const screenValues = control.value;

    if (Array.isArray(screenValues) && screenValues.length > 0) {
      let hasEmptyValues = false;
      let saveButtonReq = false;
      if (
          (screenValues[0]?.screen2Values && Array.isArray(screenValues[0].screen2Values)) ||
          (screenValues[0]?.screen3Values && Array.isArray(screenValues[0].screen3Values))
      ) {
        hasEmptyValues = screenValues.some((item: ComplexRating) => {
          return (
            (screen2Req && (!item.screen2Values || item.screen2Values.length === 0)) ||
            (screen3Req && (!item.screen3Values || item.screen3Values.length === 0))
          );
        });
      } else if (screen2Req || screen3Req) {
        hasEmptyValues = true;
        saveButtonReq = true;
      }

      return hasEmptyValues ? { screenValuesMissing: true, screen2Req, screen3Req, saveButtonReq } : null;
    }

    return null;
  };
}

export function validatePattern(patternText: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    try {
      if (!patternText) {
        return null;
      }

      const pattern = new RegExp(patternText);

      if (control.value && !pattern.test(control.value)) {
        return { patternMismatch: true };
      } else {
        return null;
      }
    } catch (error) {
      console.error(`Invalid regular expression: ${patternText}`);
      return null;
    }
  };
}
